<template>
  <h3>
    <img
      class="icon"
      src="~/assets/orienteer-flag.svg"
      alt="Feature 1"
    />Orienteer
  </h3>
</template>

<style scoped>
h3 {
  /* Box properties */
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* Background & borders */
  /* Typography */
  color: var(--brand);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.05em;
  /* Animation */
  /* Other */
}

h3:hover {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  color: var(--dark-brand);
  /* Animation */
  /* Other */
}

h3 > img {
  /* Box properties */
  box-shadow: var(--subtle-shadow);
  border-radius: 50%;
  width: var(--grid);
  height: var(--grid);
  margin-right: var(--half-grid);
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}
</style>
